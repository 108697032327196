import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import Paragraph from "../../../../../components/paragraph"
import MoodBarometer from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/mood-barometer"

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const course = {
    courseId: "reiseerinnerung",
    chapterId: "01-suedfrankreich",
  }
  const answer1 = getAnswer({
    ...course,
    taskId: "stimmungsbarometer-1",
  })
  const answer2 = getAnswer({
    ...course,
    taskId: "stimmungsbarometer-2",
  })
  const answer3 = getAnswer({
    ...course,
    taskId: "stimmungsbarometer-3",
  })

  const calculateAverageMoods = (moodDicts) => {
    const filteredDicts = moodDicts.filter((dict) => !!dict)
    const uniqueIds = [
      ...new Set(filteredDicts.map((moodDict) => Object.keys(moodDict)).flat()),
    ]
    const averageMoods = uniqueIds.reduce((acc, id) => {
      const summedMood = filteredDicts.reduce((acc, dict) => {
        const value = dict[id] ? dict[id] : 0
        return acc + value
      }, 0)
      const averageMood = Math.round(summedMood / filteredDicts.length)
      acc[id] = averageMood
      return acc
    }, {})
    return averageMoods
  }

  const averageMoods = calculateAverageMoods([answer1, answer2, answer3])

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/reisepass" />
      }
    >
      <Seo title="Hölderlin im Stimmungsbarometer" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlin im Stimmungsbarometer
        </Heading>
        <Paragraph>
          Dein Stimmungsbarometer zu den Briefen im Durchschnitt:
        </Paragraph>
        <MoodBarometer disabled initialState={averageMoods} />
        <Paragraph>
          Von der Hauslehrer-Stelle in Bordeaux versprach sich Hölderlin einen
          Neuanfang. Wie aus dem Brief an seinen Freund Böhlendorf hervorgeht,
          setzte er viele Hoffnungen darauf. Auch die Briefe an die Verwandten
          in der Heimat klingen zuversichtlich, wenngleich sie auch die
          Abenteuer und Strapazen der Reise erahnen lassen.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
